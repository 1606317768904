<template>
<div class="RepreneurDashboard">
  <div class="container-fluid">
    <b-modal size="lg" ref="handleCandidatureModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Voir votre candidature </strong>
      </template>
      <div class="row">
        <div class="col-12">
          <strong class="gavc-text-green"> <u>Présentation</u> </strong>
          <p style="font-size: 0.9rem; white-space: pre-line">
            {{ handleCandidature.description }}
          </p>
        </div>
      </div>
    </b-modal>

    <b-modal size="lg" ref="informationAreNotCompletedModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Vos informations sont incomplètes </strong>
      </template>
      <div class="row">
        <div class="col-12">
          <p class="gavc-chapo">
            ⚠️ Veuillez compléter vos informations de profil avant de pouvoir passer aux étapes suivantes
          </p>
        </div>
      </div>
    </b-modal>

    <div class="row p-3">
      <div class="col-12 col-xl-6 offset-xl-1">
        <p class="tk-congenial">Bienvenue dans votre espace repreneur</p>

        <h1 class="gavc-h1 tk-congenial mt-4">
          Vous êtes repreneur,<br>
          Trouvez l'entreprise idéale !
        </h1>

        <p class="gavc-question mt-4">
          Avec Garance à vos côtés, reprendre une entreprise n'a jamais été aussi simple !<br>
          La céder non plus.
        </p>


        <p class="gavc-chapo" v-if="isRepreneurInformationCompleted">
          <i class="gavc-icon gavc-icon-check"></i> Félicitations vos informations sont complétées
        </p>
        <p class="gavc-chapo gavc-text-rose" v-else>
          <i class="gavc-icon gavc-icon-cross"></i> Vos informations ne sont pas entièrement complétées
        </p>

        <div class="mt-4 gavc-p">
          <p v-if="repreneur.completed_rate === 100">
            <i class="gavc-icon gavc-icon-check"></i> Votre profil est complété
          </p>
          <p v-else class="gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross"></i> Veuillez compléter les informations de votre profil
          </p>
        </div>

        <div v-if="!repreneur.is_personne_physique" class="mt-4 gavc-p">
          <p v-if="repreneur.company.completed_rate === 100">
            <i class="gavc-icon gavc-icon-check"></i> Le profil de votre entreprise est complet
          </p>
          <p v-else class="gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross"></i> Veuillez compléter les informations de votre entreprise
          </p>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <router-link class="gavc-btn" to="/repreneur/editer-mon-profil">
              Editer mon profil
            </router-link>
            <router-link v-if='!repreneur.is_personne_physique' class="gavc-btn" to="/repreneur/mon-entreprise">
              Editer mon entreprise
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-4 mt-4 mt-xl-0">
        <div class="gavc-card-offer gavc-padding-large">
          <div class="gavc-card-offer__header gavc-margin-bottom">
            <div>
              <div class="gavc-question"> votre profil <span class="gavc-h1"> {{repreneur.completed_rate}}% </span> </div>
              <div class="gavc-card-progress-bar">
                <div class="gavc-card-progress-bar gavc-card-progress-bar--active" :style="`width: ${repreneur.completed_rate}%;`"></div>
              </div>
              <router-link v-if="repreneur.completed_rate < 100" class="gavc-btn-secondary mt-2" to="/repreneur/editer-mon-profil">
                <span class="gavc-text-icon">Compléter mon profil</span>
                <i class="gavc-icon gavc-icon-arrow-right"></i>
              </router-link>
            </div>
            <div class="gavc-margin-top" v-if="!repreneur.is_personne_physique">
              <div class="gavc-question"> profil de votre entreprise <span class="gavc-h1"> {{repreneur.company.completed_rate}}% </span> </div>
              <div class="gavc-card-progress-bar">
                <div class="gavc-card-progress-bar gavc-card-progress-bar--active" :style="`width: ${repreneur.company.completed_rate}%;`"></div>
              </div>
              <router-link v-if="repreneur.company.completed_rate < 100" class="gavc-btn-secondary mt-2" to="/repreneur/mon-entreprise">
                <span class="gavc-text-icon">Compléter le profil de mon entreprise</span>
                <i class="gavc-icon gavc-icon-arrow-right"></i>
              </router-link>
            </div>
          </div>
          <div class="gavc-card-offer__content gavc-padding-small">
            <h1 class="gavc-h1">{{totalNbAnnonces}}
              <span v-if="totalNbAnnonces <= 1">offre</span>
              <span v-else> offres</span>
            </h1>
            <h2 class="gavc-h2" v-if="totalNbAnnonces <= 1">attend un repreneur !</h2>
            <h2 class="gavc-h2" v-else>attendent un repreneur !</h2>
            <button v-if="isRepreneurInformationCompleted" @click="$router.push('/offres-de-cession/recherche')" class="gavc-btn gavc-margin-top gavc-margin-bottom">
              rechercher une offre de cession
            </button>
            <button v-else :disabled="true" class="gavc-btn gavc-margin-top gavc-margin-bottom">
              Complétez votre profil avant <br>de candidater à une offre
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-3 mt-2 mb-2">
        <div class="col-12 offset-xl-1 ">
          <besoinAideComponent />
        </div>
      </div>
    </div>

    <div id="gavc-stepper-dashboard" class="gavc-bg-bizarre p-3">
      <ul class="gavc-stepper-dashboard" style="margin-top: 50px;">
        <li
          class="gavc-stepper-dashboard__item  gavc-text-center"
          v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 1, 'gavc-stepper-dashboard__item--is-active': activeStep === 1 }"
          >
          <p class="gavc-step-title">Annonce</p>
          <p class="gavc-step-number">1</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 2, 'gavc-stepper-dashboard__item--is-active': activeStep === 2 }">
          <p class="gavc-step-title">Candidature</p>
          <p class="gavc-step-number">2</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 3, 'gavc-stepper-dashboard__item--is-active': activeStep === 3 }">
          <p class="gavc-step-title">Pourparlers</p>
          <p class="gavc-step-number">3</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 4, 'gavc-stepper-dashboard__item--is-active': activeStep === 4 }">
          <p class="gavc-step-title">Audit</p>
          <p class="gavc-step-number">4</p>
        </li>
        <li class="gavc-stepper-dashboard__item gavc-text-center"
            v-bind:class="{ 'gavc-stepper-dashboard__item--passed': activeStep > 5, 'gavc-stepper-dashboard__item--is-active': activeStep === 5 }">
          <p class="gavc-step-title">Closing</p>
          <p class="gavc-step-number">5</p>
        </li>
      </ul>

      <div style="margin-top: 50px;">
        <span class="pr-3 gavc-h1 tk-congenial mt-4" style="border-right: 3px solid black;">
          Mes candidatures
        </span>
        <span class="gavc-text-green ml-4">
          {{repreneur.candidatures.length}}
           <span v-if="repreneur.candidatures.length <= 1">
             candidature
           </span>
           <span v-else>
             candidatures
           </span>
        </span>

        <p
          class="gavc-chapo gavc-strong gavc-text-rose"
          v-if="!isRepreneurInformationCompleted"
        >
          Veuillez compléter vos informations avant d'accéder aux étapes suivantes
        </p>

        <div class="row mt-4" v-for="candidature in repreneur.candidatures" :key="candidature.id">
          <div class="col-12" @click="accessItem(candidature)">
            <candidatureItem style="cursor: pointer;" :candidature="candidature" :withBaseApiUrl="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  APIRepreneur
} from '@/api/APIRepreneur'
import renderMixins from '@/mixins/renderMixins'

const besoinAideComponent = () => import('@/components/layout/besoinAideComponent')
const candidatureItem = () => import('@/components/candidature/repreneur/candidatureItem')
const apiRepreneurService = new APIRepreneur()

export default {
  name: 'RepreneurDashboard',
  components: {
    besoinAideComponent,
    candidatureItem
  },
  mixins: [
    renderMixins,
  ],
  data: function() {
    return {
      handleCandidature: {
        description: '',
        id: '',
        repreneur: {
          phone_number: '',
          user: {
            first_name: '',
            last_name: '',
            username: ''
          },
        }
      },
      repreneur: {
        address: '',
        birthdate: '',
        candidatures: [],
        city: '',
        company: null,
        completion_rate: 0,
        is_personne_physique: true,
        phone_number: '',
        user: {
          first_name: '',
          last_name: '',
          username: '',
        },
        zip_code: '',
      },
      totalNbAnnonces: ''
    }
  },
  computed: {
    activeStep () {
      if (this.closings.length) {
        return 5
      }
      if (this.audits.length) {
        return 4
      }
      if (this.pourparlers.length) {
        return 3
      }
      if (this.repreneur.candidatures.length) {
        return 2
      }
      return 1
    },
    audits() {
      const audits = []
      this.repreneur.candidatures.forEach((item) => {
        if (item.audit && !item.closing) {
          audits.push(item.audit)
        }
      });
      return audits
    },
    closings() {
      const closings = []
      this.repreneur.candidatures.forEach((item) => {
        if (item.closing) {
          closings.push(item.closing)
        }
      });
      return closings
    },
    token() {
      return this.$store.state.auth.token
    },
    isRepreneurInformationCompleted() {
      if ((this.repreneur.is_personne_physique && this.repreneur.completed_rate === 100) ||
        (!this.repreneur.is_personne_physique && this.repreneur.completed_rate === 100 &&
          this.repreneur.company.completed_rate === 100)) {
        return true
      }
      return false
    },
    pourparlers() {
      const pourparlers = []
      this.repreneur.candidatures.forEach((item) => {
        if (item.pourparler && !item.audit) {
          pourparlers.push(item.pourparler)
        }
      });
      return pourparlers
    }
  },
  created: function() {
    this.getConnectedRepreneur()
    this.getTotalNbAnnonces()
  },
  methods: {
    getConnectedRepreneur() {
      this.$store.commit('loadFromAPI')
      apiRepreneurService.getConnectedRepreneur(this.token)
        .then((result) => {
          this.repreneur = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },
    getTotalNbAnnonces() {
      this.$store.commit('loadFromAPI')
      apiRepreneurService.getTotalNbAnnonces(this.token)
        .then((result) => {
          this.totalNbAnnonces = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },
    accessItem(item) {
      if (!this.isRepreneurInformationCompleted) {
        this.$refs.informationAreNotCompletedModal.show()
        return
      }

      if (item.closing) {
        this.$router.push(`/closing/${item.closing.id}`)
      } else if (item.audit) {
        if (item.audit.audit_step_ongoing === 3) {
          this.$router.push(`/audit/paiement-prix/${item.audit.paiement_prix_id}`)
        }
        else if (item.audit.audit_step_ongoing === 2) {
          this.$router.push(`/audit/entreprise-cedee/${item.audit.id}`)
        }
        else {
          this.$router.push(`/audit/situation-juridique/${item.audit.situation_juridique_id}`)
        }
      } else if (item.pourparler) {
        this.$router.push(`/pourparler/${item.pourparler.id}`)
      } else {
        this.handleCandidature = item
        this.$refs.handleCandidatureModal.show()
      }
    }
  }
}
</script>
